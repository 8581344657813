import React from "react";
import "./Shapes.css";

const Shapes = () => {
  return (
    <div className="l_shapes">
      <span className="one anim_scale">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27.23 27.24"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M24.73,13.62A11.12,11.12,0,1,1,13.62,2.5,11.11,11.11,0,0,1,24.73,13.62Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="two anim_moveLeft">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 69.99 90.04"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#ff451b;}" }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <circle className="cls-1" cx={5} cy={5} r={5} />
              <circle className="cls-1" cx={25} cy={5} r={5} />
              <circle className="cls-1" cx={45} cy={5} r={5} />
              <circle className="cls-1" cx="64.99" cy={5} r={5} />
              <circle className="cls-1" cx={5} cy="25.02" r={5} />
              <circle className="cls-1" cx={25} cy="25.02" r={5} />
              <circle className="cls-1" cx={45} cy="25.02" r={5} />
              <circle className="cls-1" cx="64.99" cy="25.02" r={5} />
              <circle className="cls-1" cx={5} cy="45.02" r={5} />
              <circle className="cls-1" cx={25} cy="45.02" r={5} />
              <circle className="cls-1" cx={45} cy="45.02" r={5} />
              <circle className="cls-1" cx="64.99" cy="45.02" r={5} />
              <circle className="cls-1" cx={5} cy="65.04" r={5} />
              <circle className="cls-1" cx={25} cy="65.04" r={5} />
              <circle className="cls-1" cx={45} cy="65.04" r={5} />
              <circle className="cls-1" cx="64.99" cy="65.04" r={5} />
              <circle className="cls-1" cx={5} cy="85.04" r={5} />
              <circle className="cls-1" cx={25} cy="85.04" r={5} />
              <circle className="cls-1" cx={45} cy="85.04" r={5} />
              <circle className="cls-1" cx="64.99" cy="85.04" r={5} />
            </g>
          </g>
        </svg>
      </span>
      <span className="three anim_moveTop">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 71.01 113.01"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#ffb324;}" }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <circle className="cls-1" cx={4} cy={4} r={4} />
              <circle className="cls-1" cx={25} cy={4} r={4} />
              <circle className="cls-1" cx="46.01" cy={4} r={4} />
              <circle className="cls-1" cx="67.01" cy={4} r={4} />
              <circle className="cls-1" cx={4} cy={25} r={4} />
              <circle className="cls-1" cx={25} cy={25} r={4} />
              <circle className="cls-1" cx="46.01" cy={25} r={4} />
              <circle className="cls-1" cx="67.01" cy={25} r={4} />
              <circle className="cls-1" cx={4} cy="46.01" r={4} />
              <circle className="cls-1" cx={25} cy="46.01" r={4} />
              <circle className="cls-1" cx="46.01" cy="46.01" r={4} />
              <circle className="cls-1" cx="67.01" cy="46.01" r={4} />
              <circle className="cls-1" cx={4} cy="67.01" r={4} />
              <circle className="cls-1" cx={25} cy="67.01" r={4} />
              <circle className="cls-1" cx="46.01" cy="67.01" r={4} />
              <circle className="cls-1" cx="67.01" cy="67.01" r={4} />
              <circle className="cls-1" cx={4} cy="88.01" r={4} />
              <circle className="cls-1" cx={25} cy="88.01" r={4} />
              <circle className="cls-1" cx="46.01" cy="88.01" r={4} />
              <circle className="cls-1" cx="67.01" cy="88.01" r={4} />
              <circle className="cls-1" cx={4} cy="109.01" r={4} />
              <circle className="cls-1" cx={25} cy="109.01" r={4} />
              <circle className="cls-1" cx="46.01" cy="109.01" r={4} />
              <circle className="cls-1" cx="67.01" cy="109.01" r={4} />
            </g>
          </g>
        </svg>
      </span>
      <span className="four anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48.08 48.09"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#35a8e0;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <rect
                className="cls-1"
                x="7.22"
                y="7.22"
                width="33.65"
                height="33.64"
                transform="translate(-6.75 38.47) rotate(-70.1)"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="five anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 33.94 30.55"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <line className="cls-1" x1="2.5" y1="28.05" x2="31.44" y2="2.5" />
            </g>
          </g>
        </svg>
      </span>
      <span className="six anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 39.95 42.01"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#e5007e;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polygon
                className="cls-1"
                points="32.41 22.64 37.45 5.78 19.98 4.14 2.5 2.5 14.94 21 27.37 39.51 32.41 22.64"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="seven anim_right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 54.93 34.45"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polyline
                className="cls-1"
                points="2.5 9.51 20.95 2.5 27.46 20.73"
              />
              <polyline
                className="cls-1"
                points="27.46 20.73 45.91 13.72 52.43 31.95"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="eight anim_scale">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27.23 27.24"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M24.73,13.62A11.12,11.12,0,1,1,13.62,2.5,11.11,11.11,0,0,1,24.73,13.62Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="nine anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 39.95 42.01"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#e5007e;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polygon
                className="cls-1"
                points="32.41 22.64 37.45 5.78 19.98 4.14 2.5 2.5 14.94 21 27.37 39.51 32.41 22.64"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="ten anim_moveLeft">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 54.93 34.45"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polyline
                className="cls-1"
                points="2.5 9.51 20.95 2.5 27.46 20.73"
              />
              <polyline
                className="cls-1"
                points="27.46 20.73 45.91 13.72 52.43 31.95"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="eleven anim_scale">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27.23 27.24"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M24.73,13.62A11.12,11.12,0,1,1,13.62,2.5,11.11,11.11,0,0,1,24.73,13.62Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="twelve anim_extra">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 31.21 29.09"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#35a8e0;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polyline
                className="cls-1"
                points="2.5 18.77 14.03 22.68 25.57 26.59 27.14 14.55 28.71 2.5"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="thirteen anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 33.94 30.55"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <line className="cls-1" x1="2.5" y1="28.05" x2="31.44" y2="2.5" />
            </g>
          </g>
        </svg>
      </span>
      <span className="fourteen anim_scale">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27.23 27.24"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M24.73,13.62A11.12,11.12,0,1,1,13.62,2.5,11.11,11.11,0,0,1,24.73,13.62Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="fifteen anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 39.95 42.01"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#e5007e;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polygon
                className="cls-1"
                points="32.41 22.64 37.45 5.78 19.98 4.14 2.5 2.5 14.94 21 27.37 39.51 32.41 22.64"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="sixteen anim_scale">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27.23 27.24"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M24.73,13.62A11.12,11.12,0,1,1,13.62,2.5,11.11,11.11,0,0,1,24.73,13.62Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="seventeen anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 33.94 30.55"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <line className="cls-1" x1="2.5" y1="28.05" x2="31.44" y2="2.5" />
            </g>
          </g>
        </svg>
      </span>
      <span className="eighteen anim_scale">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27.23 27.24"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M24.73,13.62A11.12,11.12,0,1,1,13.62,2.5,11.11,11.11,0,0,1,24.73,13.62Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="nineteen anim_scale">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27.23 27.24"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#ffec00;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M24.73,13.62A11.12,11.12,0,1,1,13.62,2.5,11.11,11.11,0,0,1,24.73,13.62Z"
              />
            </g>
          </g>
        </svg>
      </span>
      <span className="twenty anim_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48.08 48.09"
          className="svg replaced-svg"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  ".cls-1{fill:none;stroke:#35a8e0;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}",
              }}
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <rect
                className="cls-1"
                x="7.22"
                y="7.22"
                width="33.65"
                height="33.64"
                transform="translate(-6.75 38.47) rotate(-70.1)"
              />
            </g>
          </g>
        </svg>
      </span>
    </div>
  );
};

export default Shapes;
